import styled from '@emotion/styled';
import { breakpoints, fontSize, Typography } from '@playdapp/ui';
import { useMedia } from 'react-use';
import type { NextPage } from 'next';

import { getBreakpointQuery } from 'lib/util';

import Button from '@/components/Button';
import MetaTag from '@/components/MetaTag';
import Image from '@/components/Image';

const PageBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
`;

const Section = styled.div`
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 512px;
  z-index: 1;

  ${breakpoints.down('lg')} {
    width: 340px;
  }

  ${breakpoints.down('md')} {
    width: 310px;
  }
`;

const TextBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  p {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  ${breakpoints.down('lg')} {
    p {
      font-size: ${fontSize.h3};
    }
  }

  ${breakpoints.down('md')} {
    p {
      font-size: ${fontSize.h4};
    }
  }
`;

const Action = styled.div`
  margin-top: ${({ theme }) => theme.rem(108)};

  ${breakpoints.down('lg')} {
    margin-top: ${({ theme }) => theme.rem(76)};
  }

  ${breakpoints.down('md')} {
    margin-top: 4rem;
  }
`;

const NotFoundPage: NextPage = () => {
  const isSmallScreen = useMedia(
    getBreakpointQuery(breakpoints.only('sm')),
    false,
  );

  return (
    <>
      <MetaTag title="404 Page : PlayDapp Marketplace" />
      <PageBlock>
        <Section>
          <Background>
            <Image
              prefix="local"
              src="/404.png"
              width={512}
              height={188}
              layout="responsive"
              alt="404"
              priority
            />
          </Background>
          <TextBox>
            <Typography type="h2" component="p">
              WE ARE SORRY,
              {isSmallScreen && <br />}
              PAGE NOT FOUND!
            </Typography>
          </TextBox>
        </Section>
        <Action>
          <Button to="/">Back to Main</Button>
        </Action>
      </PageBlock>
    </>
  );
};

export default NotFoundPage;
